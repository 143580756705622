import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import LaunchIcon from '@mui/icons-material/Launch';
import LinkIcon from '@mui/icons-material/Link';
import LockClockIcon from '@mui/icons-material/LockClock';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const launcherItems = [
    {
        title: 'Briefing',
        link: '/briefing',
        icon: FlightTakeoffIcon
    },
    {
        title: 'Debriefing',
        link: '/debrief',
        icon: FlightLandIcon
    },
    {
        title: 'Opening Hours',
        link: '/opening-hours',
        icon: LockClockIcon
    },
    {
        title: 'Links',
        link: '/links',
        icon: LinkIcon
    },
    {
        title: 'OBSHelp',
        link: 'https://obshelp.limanovember.aero/',
        icon: LaunchIcon,
        external: true
    }
];

const HomeLauncher = (): React.ReactElement => {
    const navigate = useNavigate();
    const theme = useTheme();

    return (
        <Grid container>
            {launcherItems.map((item) => (
                <Grid key={item.title} item xs={12} sm={6} md={6} lg={4} padding={theme.spacing(3)}>
                    <Card
                        onClick={() => (item.external ? window.open(item.link, '_blank') : navigate(item.link))}
                        sx={{
                            cursor: 'pointer',
                            ':hover': {
                                boxShadow: theme.shadows[20]
                            }
                        }}>
                        <CardContent>
                            <Box alignItems="center" justifyContent="center" height={200} display="flex" flexDirection="column">
                                <Icon component={item.icon} fontSize="large" />
                                <Typography variant="h5">{item.title}</Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default HomeLauncher;
