import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';

const Fuelstatus = (): React.ReactElement => {
    const [fuelStatus, setFuelstatus] = useState<any>(null);

    useEffect(() => {
        fetch('https://winter-mountain-d82e.haugalandflyklubb.workers.dev/', {
            headers: { Authorization: 'K@DjARk&MNE7hbe@Vvu8S3Rfq2wRaR6G5TjHv%6GXbcF*7Ed3QyvT8sS%X7F5hfes' }
        })
            .then((result) => result.json())
            .then((result) => {
                setFuelstatus(result);
            });
    }, []);

    const hogLastUpdated = useMemo(() => (fuelStatus?.hfk ? dayjs(fuelStatus.hfk.lastUpdated).fromNow() : ''), [fuelStatus]);
    const gjpLastUpdated = useMemo(() => (fuelStatus?.hsfk ? dayjs(fuelStatus.hsfk.lastUpdated).fromNow() : ''), [fuelStatus]);

    const hogHangarPercentage = useMemo(() => (fuelStatus?.hfk ? (fuelStatus.hfk.hangar / fuelStatus.hfk.hangarMax) * 100 : 0), [fuelStatus]);
    const gjpHangarPercentage = useMemo(() => (fuelStatus?.hsfk ? (fuelStatus.hsfk.hangar / fuelStatus.hsfk.hangarMax) * 100 : 0), [fuelStatus]);

    const hogHangarColor = useMemo(() => {
        if (hogHangarPercentage >= 50) {
            return 'success';
        } else if (hogHangarPercentage >= 25) {
            return 'warning';
        } else {
            return 'error';
        }
    }, [fuelStatus]);

    const hogAirplaneColor = useMemo(() => {
        if (!fuelStatus || !fuelStatus.hfk) {
            return 'info';
        }

        if (fuelStatus.hfk.airplane >= 4) {
            return 'success';
        } else if (fuelStatus.hfk.airplane >= 2) {
            return 'warning';
        } else {
            return 'error';
        }
    }, [fuelStatus]);

    const gjpAirplaneColor = useMemo(() => {
        if (!fuelStatus || !fuelStatus.hsfk) {
            return 'info';
        }

        if (fuelStatus.hsfk.airplane >= 4) {
            return 'success';
        } else if (fuelStatus.hsfk.airplane >= 2) {
            return 'warning';
        } else {
            return 'error';
        }
    }, [fuelStatus]);

    const gjpHangarColor = useMemo(() => {
        if (gjpHangarPercentage >= 50) {
            return 'success';
        } else if (gjpHangarPercentage >= 25) {
            return 'warning';
        } else {
            return 'error';
        }
    }, [fuelStatus]);

    if (!fuelStatus || !fuelStatus.hfk || !fuelStatus.hsfk) {
        return <></>;
    }

    return (
        <>
            <Typography variant="h6" component="h4">
                Fuelstatus - HFK
            </Typography>
            <Box>
                <b>Hangar</b>
                <LinearProgress variant="determinate" value={hogHangarPercentage} color={hogHangarColor} sx={{ height: 10 }} />
                <span style={{ float: 'right' }}>
                    <b>{fuelStatus.hfk.hangar} l</b>
                </span>
            </Box>
            <Box mt={1}>
                <b>LN-HOG</b>
                <LinearProgress variant="determinate" value={(fuelStatus.hfk.airplane / 8) * 100} color={hogAirplaneColor} sx={{ height: 10 }} />
                <small>Last updated {hogLastUpdated}</small>
                <span style={{ float: 'right' }}>
                    <b>{fuelStatus.hfk.airplane}/8</b>
                </span>
            </Box>

            <Typography variant="h6" component="h4" sx={{ marginTop: 2 }}>
                Fuelstatus - HSFK
            </Typography>
            <Box>
                <b>Hangar</b>
                <LinearProgress variant="determinate" value={gjpHangarPercentage} color={gjpHangarColor} sx={{ height: 10 }} />
                <span style={{ float: 'right' }}>
                    <b>{fuelStatus.hsfk.hangar} l</b>
                </span>
            </Box>
            <Box mt={1}>
                <b>LN-GJP</b>
                <LinearProgress variant="determinate" value={(fuelStatus.hsfk.airplane / 8) * 100} color={gjpAirplaneColor} sx={{ height: 10 }} />
                <small>Last updated {gjpLastUpdated}</small>
                <span style={{ float: 'right' }}>
                    <b>{fuelStatus.hsfk.airplane}/8</b>
                </span>
            </Box>
        </>
    );
};

export default Fuelstatus;
