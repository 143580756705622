import { PaletteMode } from '@mui/material';
import Container from '@mui/material/Container';
import { createTheme } from '@mui/material/styles';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { renderTimeViewClock } from '@mui/x-date-pickers';
import React from 'react';
import useLocalStorageState from 'use-local-storage-state';
import UnhandledExceptionBoundary from './common/UnhandledExceptionBoundary';
import Footer from './Footer';
import Header from './Header';
import Routes from './Routes';

export const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

const Layout = (): React.ReactElement => {
    const [mode, setMode] = useLocalStorageState<'light' | 'dark'>('color_theme', { defaultValue: 'dark' });

    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode: PaletteMode) => (prevMode === 'light' ? 'dark' : 'light'));
            }
        }),
        []
    );

    const theme = createTheme(
        {
            palette: {
                mode,
                primary: {
                    main: mode === 'light' ? '#005282' : '#0092e8'
                },
                secondary: {
                    main: '#f50057'
                }
            },
            components: {
                MuiChip: {
                    styleOverrides: {
                        root: ({ ownerState }) => ({
                            backgroundColor: ownerState.variant === 'outlined' ? 'white' : ''
                        })
                    }
                },
                // @ts-ignore
                MuiDesktopTimePicker: {
                    defaultProps: {
                        viewRenderers: {
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock
                        }
                    }
                }
            },
            mixins: {
                toolbar: {
                    background: mode === 'light' ? '#005282' : '#005282'
                }
            }
        },
        [mode]
    );

    return (
        <UnhandledExceptionBoundary>
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <Header />
                    <Container
                        maxWidth={false}
                        component="main"
                        sx={{ height: '100%', width: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column' }}
                        style={{ padding: 0, margin: 0 }}>
                        <Container
                            sx={{
                                flex: '1 0 auto',
                                paddingTop: theme.spacing(2),
                                paddingBottom: theme.spacing(7),
                                backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#121212'
                            }}
                            maxWidth={false}>
                            <UnhandledExceptionBoundary>
                                <Routes />
                            </UnhandledExceptionBoundary>
                        </Container>
                        <Footer />
                    </Container>
                </ThemeProvider>
            </ColorModeContext.Provider>
        </UnhandledExceptionBoundary>
    );
};

export default Layout;
