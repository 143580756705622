import { XMLParser } from 'fast-xml-parser';

export const parseTcx = (xml: string) => {
    const parser = new XMLParser();
    const parsedXml = parser.parse(xml);

    if (!parsedXml.TrainingCenterDatabase || !parsedXml.TrainingCenterDatabase.Activities || !parsedXml.TrainingCenterDatabase.Activities.Activity) {
        return undefined;
    }

    const creator = parsedXml.TrainingCenterDatabase.Activities.Activity.Creator.Name ?? 'Makani';
    const name = parsedXml.TrainingCenterDatabase.Activities.Activity.Id;
    const points = parsedXml.TrainingCenterDatabase.Activities.Activity.Lap.Track.Trackpoint.filter((point: any) => !!point.Position).map((point: any) => {
        return {
            time: point.Time,
            lat: point.Position.LatitudeDegrees,
            lon: point.Position.LongitudeDegrees,
            ele: point.AltitudeMeters,
            speed: point.Extensions?.['ns3:TPX']?.['ns3:Speed'] ?? 0
        };
    });

    return {
        creator,
        name,
        points
    };
};
