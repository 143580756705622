import React from 'react';
import { Route, Routes as RouterRoutes } from 'react-router-dom';
import Briefing from './briefing/Briefing';
import Debrief from './debrief/Debrief';
import Home from './home/Home';
import Links from './links/Links';
import OpeningHours from './opening-hours/OpeningHours';

const Routes = (): React.ReactElement => {
    return (
        <RouterRoutes>
            <Route path="/briefing" element={<Briefing />} />
            <Route path="/debrief" element={<Debrief />} />
            <Route path="/links" element={<Links />} />
            <Route path="/opening-hours" element={<OpeningHours />} />
            <Route path="/" element={<Home />} />
        </RouterRoutes>
    );
};

export default Routes;
