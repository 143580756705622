import dayjs, { Dayjs } from 'dayjs';
import { OpeningHourEvent } from '../../models/OpeningHourEvent';

const notamDateFormat = 'MM/DD/YYYY HHmm';
const weekdays = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

const createTitle = (title: string, start: Dayjs, end: Dayjs, isClosed: boolean): string => {
    return isClosed ? `🔒 ${title}` : `🛫 ${title} - ${start.format('HH:mm')}-${end.format('HH:mm')}`;
};

const openingHoursToEvents = (notam: any): OpeningHourEvent[] => {
    const title = notam.facilityDesignator;

    dayjs.tz.setDefault('UTC');

    const startDate = dayjs(notam.startDate, notamDateFormat);
    const endDate = dayjs(notam.endDate, notamDateFormat);

    let rawText = notam.icaoMessage.split('HR OF SER').at(1)?.trim()?.replaceAll('\n', ' ').split('ON REQ').at(0);
    if (rawText.includes(':')) {
        rawText = rawText.split(': ').at(1);
    }

    const openingParts = rawText.split(', ').map((p: string) => (p.includes('. ') ? p.split('. ').at(0) : p));
    const weekdayOpenings = openingParts.flatMap((p: string) => {
        const openings = [];
        if (weekdays.filter((w) => p.startsWith(w)).length > 0) {
            const parts = p.split(' ');
            // @ts-ignore
            const dayRange = parts.shift().split('-');
            const timeRangesUtc = parts.filter((x) => x !== '').map((x: string) => x.split('-'));

            for (const timeRangeUtc of timeRangesUtc) {
                if (dayRange.length === 1) {
                    if (timeRangeUtc[0] === 'CLSD') {
                        openings.push({ day: dayRange[0], startUtc: null, endUtc: null });
                    } else {
                        openings.push({ day: dayRange[0], startUtc: timeRangeUtc[0], endUtc: timeRangeUtc[1] });
                    }
                } else {
                    let started = false;
                    let ended = false;

                    for (const d of weekdays) {
                        if (!started && d === dayRange[0]) {
                            started = true;
                        }

                        if (started && !ended) {
                            if (timeRangeUtc[0] === 'CLSD') {
                                openings.push({ day: d, startUtc: null, endUtc: null });
                            } else {
                                openings.push({ day: d, startUtc: timeRangeUtc[0], endUtc: timeRangeUtc[1] });
                            }
                        }

                        if (started && d === dayRange[1]) {
                            ended = true;
                        }
                    }
                }
            }
        }

        dayjs.tz.setDefault();

        return openings;
    });

    const dateOpenings = [];
    if (months.filter((m) => openingParts.at(0)?.startsWith(m)).length > 0) {
        let month = openingParts.at(0).split(' ').at(0);

        for (const openingPart of openingParts) {
            const parts = openingPart.split(' ');

            if (months.filter((m) => m === parts[0]).length > 0) {
                month = months.indexOf(parts[0]) + 1;
                parts.shift();
            }

            const dayRange = parts
                .shift()
                .split('-')
                .map((d: string) => parseInt(d, 10));
            const timeRangesUtc = parts.filter((x: string) => x !== '').map((p: string) => p.split('-'));

            for (const timeRangeUtc of timeRangesUtc) {
                if (dayRange.length === 1) {
                    if (timeRangeUtc[0] === 'CLSD') {
                        dateOpenings.push({ month, day: dayRange[0], startUtc: null, endUtc: null });
                    } else {
                        dateOpenings.push({ month, day: dayRange[0], startUtc: timeRangeUtc[0], endUtc: timeRangeUtc[1] });
                    }
                } else {
                    let started = false;
                    let ended = false;

                    for (let d = dayRange[0]; d <= dayRange[1]; d++) {
                        if (!started && d === dayRange[0]) {
                            started = true;
                        }

                        if (started && !ended) {
                            if (timeRangeUtc[0] === 'CLSD') {
                                dateOpenings.push({ month, day: d, startUtc: null, endUtc: null });
                            } else {
                                dateOpenings.push({ month, day: d, startUtc: timeRangeUtc[0], endUtc: timeRangeUtc[1] });
                            }
                        }

                        if (started && d === dayRange[1]) {
                            ended = true;
                        }
                    }
                }
            }
        }
    }

    const events = [];

    let dateMarker = dayjs.utc(startDate);
    while (dateMarker < endDate) {
        const dateBased = dateOpenings.filter((d) => d.month === dateMarker.month() + 1 && d.day === dateMarker.date());
        if (dateBased.length > 0) {
            for (const opening of dateBased) {
                const isClosed = opening.startUtc === null;

                const start = dayjs(dateMarker)
                    .set('hour', opening.startUtc?.substring(0, 2) ?? 2)
                    .set('minute', opening.startUtc?.substring(2, 4) ?? 2)
                    .tz('Europe/Oslo');
                const end = dayjs(dateMarker)
                    .set('hour', opening.endUtc?.substring(0, 2) ?? 20)
                    .set('minute', opening.endUtc?.substring(2, 4) ?? 0)
                    .tz('Europe/Oslo');
                events.push({
                    title: createTitle(title, start, end, isClosed),
                    start: start.toDate(),
                    end: end.toDate(),
                    allDay: isClosed,
                    type: isClosed ? 'Closed' : 'Open'
                });
            }
        } else {
            const relevantWeekday = weekdayOpenings.filter((x: any) => weekdays.indexOf(x.day) + 1 === dateMarker.isoWeekday());
            for (const opening of relevantWeekday) {
                const isClosed = opening.startUtc === null;

                const start = dayjs(dateMarker)
                    .set('hour', opening.startUtc?.substring(0, 2) ?? 2)
                    .set('minute', opening.startUtc?.substring(2, 4) ?? 2)
                    .tz('Europe/Oslo');
                const end = dayjs(dateMarker)
                    .set('hour', opening.endUtc?.substring(0, 2) ?? 20)
                    .set('minute', opening.endUtc?.substring(2, 4) ?? 0)
                    .tz('Europe/Oslo');
                events.push({
                    title: createTitle(title, start, end, isClosed),
                    start: start.toDate(),
                    end: end.toDate(),
                    allDay: isClosed,
                    type: isClosed ? 'Closed' : 'Open'
                });
            }
        }

        dateMarker = dateMarker.add(1, 'day');
    }

    return events;
};

export default openingHoursToEvents;
