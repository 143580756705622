import { Typography, useTheme } from '@mui/material';
import 'flag-icons/css/flag-icons.css';
import React, { useMemo } from 'react';
import RegistrationPrefixes from '../../data/registrations.json';
import { isoCodeToCountryName } from '../../tools/CountryTools';

const AircraftCountrySearch = ({ search }: { search: string }): React.ReactElement => {
    const theme = useTheme();

    const prefix = useMemo(() => {
        const searchRegistrationPrefix = search.trim().toUpperCase();
        return RegistrationPrefixes.filter((a: any) => searchRegistrationPrefix.startsWith(a.prefix)).at(0);
    }, [search]);

    if (!prefix) {
        return <></>;
    }

    return (
        <Typography variant="body1" sx={{ color: theme.palette.text.primary, fontStyle: 'italic' }}>
            <span className={`fi fi-${prefix?.country?.toLowerCase()}`} title={isoCodeToCountryName(prefix?.country)}></span>{' '}
            {isoCodeToCountryName(prefix?.country)}
        </Typography>
    );
};

export default AircraftCountrySearch;
