import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import HomeIcon from '@mui/icons-material/Home';
import LinkIcon from '@mui/icons-material/Link';
import LockClockIcon from '@mui/icons-material/LockClock';
import MenuIcon from '@mui/icons-material/Menu';
import { ListItemButton } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Header = (): React.ReactElement => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const drawerWidth = 240;

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (
        <AppBar position="sticky">
            <Toolbar>
                <Hidden mdUp>
                    <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={toggleDrawer}>
                        <MenuIcon />
                    </IconButton>
                </Hidden>
                <img src="/logo.svg" alt="Logo" style={{ maxHeight: '34px', maxWidth: '90%', padding: '10px', filter: 'invert(1)' }} />
                <Typography variant="h6" sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                    Makani
                </Typography>
                <Hidden smDown>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton edge="start" color="inherit" aria-label="home" title="Home" sx={{ mr: 2 }} onClick={() => navigate('/')}>
                        <HomeIcon />
                    </IconButton>
                    <IconButton edge="start" color="inherit" aria-label="briefing" title="Briefing" sx={{ mr: 2 }} onClick={() => navigate('/briefing')}>
                        <FlightTakeoffIcon />
                    </IconButton>
                    <IconButton edge="start" color="inherit" aria-label="debrief" title="Debriefing" sx={{ mr: 2 }} onClick={() => navigate('/debrief')}>
                        <FlightLandIcon />
                    </IconButton>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="opening-hours"
                        title="Opening Hours"
                        sx={{ mr: 2 }}
                        onClick={() => navigate('/opening-hours')}>
                        <LockClockIcon />
                    </IconButton>
                    <IconButton edge="start" color="inherit" aria-label="links" title="Links" sx={{ mr: 2 }} onClick={() => navigate('/links')}>
                        <LinkIcon />
                    </IconButton>
                </Hidden>
            </Toolbar>
            <Drawer open={drawerOpen} onClose={toggleDrawer}>
                <List sx={{ width: drawerWidth, backgroundColor: theme?.palette?.background?.paper ?? 'white' }}>
                    <ListItem>
                        <img
                            src="/logo.svg"
                            alt="Logo"
                            style={{ maxHeight: '34px', maxWidth: '90%', padding: '10px', filter: theme.palette.mode === 'dark' ? 'invert(1)' : undefined }}
                        />
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemButton key="home" component="a" href="/">
                            <ListItemIcon>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Home" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton key="briefing" component="a" href="/briefing">
                            <ListItemIcon>
                                <FlightTakeoffIcon />
                            </ListItemIcon>
                            <ListItemText primary="Briefing" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton key="debrief" component="a" href="/debrief">
                            <ListItemIcon>
                                <FlightLandIcon />
                            </ListItemIcon>
                            <ListItemText primary="Debriefing" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton key="opening-hours" component="a" href="/opening-hours">
                            <ListItemIcon>
                                <LockClockIcon />
                            </ListItemIcon>
                            <ListItemText primary="Opening Hours" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton key="links" component="a" href="/links">
                            <ListItemIcon>
                                <LinkIcon />
                            </ListItemIcon>
                            <ListItemText primary="Links" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
        </AppBar>
    );
};

export default Header;
