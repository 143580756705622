import dayjs, { Dayjs } from 'dayjs';
import { OpeningHourEvent } from '../../models/OpeningHourEvent';

const notamDateFormat = 'MM/DD/YYYY HHmm';

const createTitle = (title: string, start: Dayjs, end: Dayjs): string => {
    return `🔒 ${title} - ${start.format('HH:mm')}-${end.format('HH:mm')} (RWY)`;
};

const rwyClosedToEvents = (notam: any): OpeningHourEvent[] => {
    const title = notam.facilityDesignator;

    dayjs.tz.setDefault('UTC');

    const startDate = dayjs.utc(notam.startDate, notamDateFormat);
    const endDate = dayjs.utc(notam.endDate, notamDateFormat);

    if (!notam.icaoMessage.includes('RWY')) {
        // Other than runway not yet supported
        return [];
    }

    dayjs.tz.setDefault();

    const events = [];

    const start = startDate.tz('Europe/Oslo');
    const end = endDate.tz('Europe/Oslo');
    events.push({
        title: createTitle(title, start, end),
        start: start.toDate(),
        end: end.toDate(),
        allDay: false,
        type: 'Rwy Closed'
    });
    console.log(events);
    return events;
};

export default rwyClosedToEvents;
