import Container from '@mui/material/Container';
import React, { useEffect } from 'react';
import { setTitle } from '../../tools/SetTitle';
import Airports from './Airports';

const Briefing = (): React.ReactElement => {
    useEffect(() => {
        setTitle('Briefing');
    }, []);

    return (
        <Container maxWidth={false}>
            <Airports />
        </Container>
    );
};

export default Briefing;
