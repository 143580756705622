import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { METAR, rawMetarToSVG } from 'metar-plot';
import React, { useMemo } from 'react';
import { Coordinates } from '../../models/Coordinates';
import Fuelstatus from './Fuelstatus';
import SunriseSunset from './SunriseSunset';

const getCrosswindFactor = (runwayHeading: number, windDirection: number | string | undefined, windVelocity: number | undefined) => {
    if (typeof windDirection !== 'number' || typeof windVelocity !== 'number') {
        return undefined;
    }

    let angle = Math.abs(runwayHeading - windDirection);
    if (angle > 180) {
        angle = 360 - angle;
    }
    const pi = Math.PI / 180;
    const sinFactor = Math.sin(angle * pi);
    const cosFactor = Math.cos(angle * pi);

    const crossWindVelocity = Math.round(windVelocity * sinFactor * 1) / 1;
    const runwayWindVelocity = Math.abs(Math.round(windVelocity * cosFactor * 1) / 1);

    let crossWindDirection;

    if (windDirection - runwayHeading > -180 ? windDirection < runwayHeading : windDirection + 360 < runwayHeading) {
        crossWindDirection = 'from the left';
    } else {
        crossWindDirection = 'from the right';
    }

    if (angle === 0) {
        crossWindDirection = 'perfect headwind';
    } else if (Math.abs(angle) === 180) {
        crossWindDirection = 'perfect tailwind';
    }

    const runwayDirection = angle < 90 ? 'headwind' : 'tailwind';

    return {
        velocity: crossWindVelocity,
        direction: crossWindDirection,
        runwayVelocity: runwayWindVelocity,
        runwayDirection: runwayDirection,
        display: angle < 90
    };
};

const Airport = ({
    icao,
    metars,
    tafs,
    notams,
    fetchingMetars,
    fetchingTafs,
    fetchingNotams,
    yr,
    yrRadar,
    yrDetails,
    windy,
    windyStation,
    runways,
    coordinates
}: {
    icao: string;
    metars: string[];
    tafs: string[];
    notams: any[];
    fetchingMetars: boolean;
    fetchingTafs: boolean;
    fetchingNotams: boolean;
    yr: string;
    yrRadar: string;
    yrDetails: string;
    windy: string;
    windyStation: string;
    runways: number[];
    coordinates: Coordinates;
}): React.ReactElement => {
    const latestMetars = useMemo(() => metars.slice(-3), [metars]);

    const parsedMetars = useMemo(() => latestMetars?.map((m: string) => new METAR(m)), [latestMetars]);
    const metarImages = useMemo(() => latestMetars.map((metar) => rawMetarToSVG(metar ?? '', '200px', '150px', true)), [latestMetars]);

    const crosswinds = useMemo(
        () =>
            runways
                .map((runway) => {
                    const latestMetar = parsedMetars.at(-1);
                    const runwayHeading = runway * 10;
                    const crosswind = getCrosswindFactor(runwayHeading, latestMetar?.wind.direction, latestMetar?.wind.speed);
                    const crosswindGust =
                        typeof latestMetar?.wind.gust === 'number' && latestMetar?.wind.gust > 0
                            ? getCrosswindFactor(runwayHeading, latestMetar?.wind.direction, latestMetar?.wind.gust)
                            : 0;

                    if (crosswind === undefined || !crosswind.display) {
                        return undefined;
                    }

                    const crosswindVelocity = crosswind.velocity + (crosswindGust && crosswindGust.velocity > 0 ? 'G' + crosswindGust.velocity : '');
                    const runwayVelocity =
                        crosswind.runwayVelocity + (crosswindGust && crosswindGust.runwayVelocity > 0 ? 'G' + crosswindGust.runwayVelocity : '');

                    return (
                        'RWY' +
                        (runway.toString().length === 1 ? '0' + runway.toString() : runway.toString()) +
                        ': ' +
                        crosswindVelocity +
                        'kt ' +
                        crosswind.direction +
                        ', ' +
                        runwayVelocity +
                        'kt ' +
                        crosswind.runwayDirection
                    );
                })
                .filter((x) => x !== undefined),
        [runways, parsedMetars]
    );

    return (
        <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
            <Card sx={{ margin: 1 }}>
                <CardContent>
                    <Typography variant="h4" component="h3">
                        {icao}
                        <Button component="a" href={windyStation} sx={{ float: 'right', marginLeft: 1 }} variant="outlined">
                            Windy (station)
                        </Button>
                        <Button component="a" href={windy} sx={{ float: 'right', marginLeft: 1 }} variant="outlined">
                            Windy
                        </Button>
                        <Button component="a" href={yrDetails} sx={{ float: 'right', marginLeft: 1 }} variant="outlined">
                            Yr.no Details
                        </Button>
                        <Button component="a" href={yrRadar} sx={{ float: 'right', marginLeft: 1 }} variant="outlined">
                            Yr.no Radar
                        </Button>
                        <Button component="a" href={yr} sx={{ float: 'right', marginLeft: 1 }} variant="outlined">
                            Yr.no
                        </Button>
                    </Typography>
                    <SunriseSunset coordinates={coordinates} />
                    <Typography variant="h6" component="h4">
                        METAR
                    </Typography>
                    {fetchingMetars && <CircularProgress />}
                    <Grid container>
                        {metarImages?.map((m: any, i: number) => (
                            <Grid
                                item
                                key={i}
                                sx={{
                                    textAlign: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyItems: 'center',
                                    border: '1px solid lightgray',
                                    padding: 2,
                                    margin: 1
                                }}>
                                <img src={`data:image/svg+xml;utf8,${encodeURIComponent(m)}`} />
                                <i>{dayjs(parsedMetars[i].time).format('HH:mm')}</i>
                            </Grid>
                        ))}
                    </Grid>
                    <pre style={{ wordWrap: 'break-word', wordBreak: 'break-word', overflowWrap: 'anywhere', whiteSpace: 'pre-wrap' }}>
                        {latestMetars.join('\n')}
                    </pre>
                    {latestMetars?.length > 0 && (
                        <>
                            <strong>Crosswind calculations</strong>
                            {crosswinds.map((rwy, index) => (
                                <pre key={index}>{rwy}</pre>
                            ))}
                        </>
                    )}
                    <Typography variant="h6" component="h4">
                        TAF
                    </Typography>
                    {fetchingTafs && <CircularProgress />}
                    <pre style={{ wordWrap: 'break-word', wordBreak: 'break-word', overflowWrap: 'anywhere', whiteSpace: 'pre-wrap' }}>{tafs.join('\n')}</pre>
                    <Typography variant="h6" component="h4">
                        NOTAMs
                    </Typography>
                    {fetchingNotams && <CircularProgress />}
                    {notams?.map((notam, index) => <pre key={index}>{notam.icaoMessage}</pre>)}
                    {icao === 'ENHD' && <Fuelstatus />}
                </CardContent>
            </Card>
        </Grid>
    );
};

export default Airport;
