export const convertMeters = (value: number, toUnit: 'nm' | 'ft') => {
    switch (toUnit) {
        case 'nm':
            return Math.round((value / 1852) * 10) / 10;
        case 'ft':
            return Math.round(value * 3.281);
        default:
            return 0;
    }
};

export const convertMsToKts = (value: number) => {
    return value * 1.944;
};

export const calculateDistance = (baseCoordinates: any, targetCoordinates: any) => {
    const baseRad = (Math.PI * baseCoordinates.Latitude) / 180;
    const targetRad = (Math.PI * targetCoordinates.Latitude) / 180;
    const theta = baseCoordinates.Longitude - targetCoordinates.Longitude;
    const thetaRad = (Math.PI * theta) / 180;

    let dist = Math.sin(baseRad) * Math.sin(targetRad) + Math.cos(baseRad) * Math.cos(targetRad) * Math.cos(thetaRad);
    dist = Math.acos(dist);

    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;

    dist = dist * 0.8684;

    return isNaN(dist) ? 0 : dist;
};
