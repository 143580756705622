import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useEffect, useMemo } from 'react';
import { setTitle } from '../../tools/SetTitle';
import { uniqueValues } from '../../tools/UniqueValues';
import { launcherItems } from './LauncherItems';

const Links = (): React.ReactElement => {
    const theme = useTheme();

    useEffect(() => {
        setTitle('Links');
    }, []);

    const uniqueCategories = useMemo(() => launcherItems.map((i) => i.category).filter(uniqueValues), [launcherItems]);
    const sortedLauncherItems = useMemo(
        () =>
            uniqueCategories.map((c) => {
                return {
                    category: c,
                    items: launcherItems.filter((i) => i.category === c).sort((a, b) => a.title.localeCompare(b.title))
                };
            }),
        [uniqueCategories, launcherItems]
    );

    return (
        <Container maxWidth={false}>
            {sortedLauncherItems.map((category) => (
                <div key={category.category}>
                    <Typography variant="h4" sx={{ color: theme.palette.text.primary }}>
                        {category.category}
                    </Typography>
                    <Grid container>
                        {category.items.map((item, index) => (
                            <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2} padding={theme.spacing(3)}>
                                <Card
                                    onClick={() => window.open(item.link, '_blank')}
                                    sx={{
                                        cursor: 'pointer',
                                        ':hover': {
                                            boxShadow: theme.shadows[20]
                                        }
                                    }}>
                                    <CardContent>
                                        <Box alignItems="center" justifyContent="center" height={200} display="flex" flexDirection="column">
                                            <Icon component={item.icon} fontSize="large" />
                                            <Typography variant="h5" sx={{ textAlign: 'center' }}>
                                                {item.title}
                                            </Typography>
                                            <Typography variant="body2" sx={{ textAlign: 'center' }}>
                                                {item.description}
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            ))}
        </Container>
    );
};

export default Links;
