import DarkModeIcon from '@mui/icons-material/DarkMode';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import * as SunCalc from 'suncalc';
import { Coordinates } from '../../models/Coordinates';

const isValidDate = (d: Date) => {
    // @ts-ignore
    return d instanceof Date && !isNaN(d);
};

const SunriseSunset = ({ coordinates }: { coordinates?: Coordinates }): React.ReactElement => {
    const theme = useTheme();

    const solarTimes = useMemo(() => SunCalc.getTimes(new Date(), coordinates?.latitude ?? 0, coordinates?.longitude ?? 0), [coordinates]);

    if (!coordinates) {
        return <></>;
    }

    if (!isValidDate(solarTimes.sunrise) && !isValidDate(solarTimes.sunset)) {
        const positionAtSolarNoon = SunCalc.getPosition(solarTimes.solarNoon, coordinates.latitude, coordinates.longitude);
        if (positionAtSolarNoon.altitude > 0) {
            return (
                <Box>
                    <WbSunnyIcon color="success" />
                    <Typography sx={{ color: theme.palette.success.main }}>Midnight Sun</Typography>
                </Box>
            );
        } else {
            return (
                <Box>
                    <DarkModeIcon color="error" />
                    <Typography sx={{ color: theme.palette.warning.main }}>Polar Night</Typography>
                </Box>
            );
        }
    }

    return (
        <Stack direction="row" justifyContent="space-between" sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}>
            <Typography color="body1">
                <WbSunnyIcon fontSize="small" /> VFR Day starts: {dayjs(solarTimes.dawn).format('HH:mm')} (sunrise: {dayjs(solarTimes.sunrise).format('HH:mm')}){' '}
            </Typography>
            <Typography color="body1">
                <DarkModeIcon fontSize="small" /> VFR Night starts: {dayjs(solarTimes.dusk).format('HH:mm')} (sunset :{' '}
                {dayjs(solarTimes.sunset).format('HH:mm')})
            </Typography>
        </Stack>
    );
};

export default SunriseSunset;
