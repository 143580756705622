import React from 'react';
import { LayersControl, TileLayer, useMapEvent } from 'react-leaflet';
import useLocalStorageState from 'use-local-storage-state';

const MapBaseLayers = (): React.ReactElement => {
    const [layer, setLayer] = useLocalStorageState<string>('map-baselayer', { defaultValue: 'Stamen Toner Lite' });

    useMapEvent('baselayerchange', (e) => {
        setLayer(e.name);
    });

    return (
        <LayersControl position="topright">
            <LayersControl.BaseLayer checked={layer === 'Stamen Toner Lite'} name="Stamen Toner Lite">
                <TileLayer
                    attribution='Map tiles by <a href="https://stamen.com">Stamen Design</a>,
<a href="https://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}.png"
                    subdomains="abcd"
                    minZoom={0}
                    maxZoom={20}
                />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer checked={layer === 'Stamen Terrain'} name="Stamen Terrain">
                <TileLayer
                    attribution='Map tiles by <a href="https://stamen.com">Stamen Design</a>,
<a href="https://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://stamen-tiles-{s}.a.ssl.fastly.net/terrain/{z}/{x}/{y}{r}.png"
                    subdomains="abcd"
                    minZoom={0}
                    maxZoom={20}
                />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer checked={layer === 'Statens Kartverk - Topografisk Norgeskart'} name="Statens Kartverk - Topografisk Norgeskart">
                <TileLayer
                    attribution='Map data &copy; <a href="https://www.statkart.no/">Statens Kartverk</a>,
                    <a href="https://www.statkart.no/nor/Land/Fagomrader/Geovekst/">Geovekst</a>,
                    <a href="https://www.statkart.no/?module=Articles;action=Article.publicShow;ID=14194">kommuner</a> og <a href="https://www.npolar.no/">Norsk Polarinstitutt</a>'
                    url="https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=topo4&zoom={z}&x={x}&y={y}"
                    minZoom={0}
                    maxZoom={20}
                />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer checked={layer === 'Statens Kartverk - Grunnkart Norge'} name="Statens Kartverk - Grunnkart Norge">
                <TileLayer
                    attribution='Map data &copy; <a href="https://www.statkart.no/">Statens Kartverk</a>,
                    <a href="https://www.statkart.no/nor/Land/Fagomrader/Geovekst/">Geovekst</a>,
                    <a href="https://www.statkart.no/?module=Articles;action=Article.publicShow;ID=14194">kommuner</a> og <a href="https://www.npolar.no/">Norsk Polarinstitutt</a>'
                    url="https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=norges_grunnkart&zoom={z}&x={x}&y={y}"
                    subdomains="abcd"
                    minZoom={0}
                    maxZoom={20}
                />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer checked={layer === 'Statens Kartverk - Grunnkart Norge Gråtone'} name="Statens Kartverk - Grunnkart Norge Gråtone">
                <TileLayer
                    attribution='Map data &copy; <a href="https://www.statkart.no/">Statens Kartverk</a>,
                    <a href="https://www.statkart.no/nor/Land/Fagomrader/Geovekst/">Geovekst</a>,
                    <a href="https://www.statkart.no/?module=Articles;action=Article.publicShow;ID=14194">kommuner</a> og <a href="https://www.npolar.no/">Norsk Polarinstitutt</a>'
                    url="https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=norges_grunnkart_graatone&zoom={z}&x={x}&y={y}"
                    subdomains="abcd"
                    minZoom={0}
                    maxZoom={20}
                />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer checked={layer === 'Statens Kartverk - Grunnkart Europa'} name="Statens Kartverk - Grunnkart Europa">
                <TileLayer
                    attribution='Map data &copy; <a href="https://www.statkart.no/">Statens Kartverk</a>'
                    url="https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=egk&zoom={z}&x={x}&y={y}"
                    subdomains="abcd"
                    minZoom={0}
                    maxZoom={20}
                />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer checked={layer === 'Statens Kartverk - Europa Forenklet'} name="Statens Kartverk - Europa Forenklet">
                <TileLayer
                    attribution='Map data &copy; <a href="https://www.statkart.no/">Statens Kartverk</a>'
                    url="https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=europa_forenklet&zoom={z}&x={x}&y={y}"
                    subdomains="abcd"
                    minZoom={0}
                    maxZoom={20}
                />
            </LayersControl.BaseLayer>
            <LayersControl.Overlay name="OpenAIP">
                <TileLayer
                    attribution='Map data &copy; <a href="https://www.openaip.net">OpenAIP</a>'
                    url="https://api.tiles.openaip.net/api/data/openaip/{z}/{x}/{y}.png?apiKey=8f2be4d754e1ae1bbd4c056068093950"
                    minZoom={0}
                    maxZoom={20}
                />
            </LayersControl.Overlay>
        </LayersControl>
    );
};

export default MapBaseLayers;
