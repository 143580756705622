import LockClockIcon from '@mui/icons-material/LockClock';
import { CircularProgress } from '@mui/material';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { Calendar as BigCalendar } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useWindowSize } from '../../hooks/useWindowSize';
import { OpeningHourEvent } from '../../models/OpeningHourEvent';
import { setTitle } from '../../tools/SetTitle';
import dayjsLocalizer from './dayjsLocalizer';
import openingHoursToEvents from './openingHoursToEvents';
import rwyClosedToEvents from './rwyClosedToEvents';

const OpeningHours = (): React.ReactElement => {
    const theme = useTheme();
    const localizer = dayjsLocalizer(dayjs);

    const { height } = useWindowSize();

    const [events, setEvents] = useState<OpeningHourEvent[]>([]);
    const [fetchingNotams, setFetchingNotams] = useState(true);

    const backgroundEvents = useMemo(() => [], []);

    // @ts-ignore
    const minDate = useMemo(() => events.at(0)?.start, [events]);
    // @ts-ignore
    const maxDate = useMemo(() => events.at(-1)?.end, [events]);

    useEffect(() => {
        fetch('https://makani.limanovember.aero/api/faa/notams')
            .then((result) => result.json())
            .then((result) => {
                const openingHoursNotams = result?.notamList?.filter(
                    (n: any) => n.traditionalMessageFrom4thWord.startsWith('AD HR OF SER') || n.traditionalMessageFrom4thWord.startsWith('AFIS HR OF SER')
                );

                const rwyClosedNotams = result?.notamList?.filter((n: any) => n.traditionalMessageFrom4thWord.includes('CLSD DUE'));

                setEvents([...openingHoursNotams.flatMap((n: any) => openingHoursToEvents(n)), ...rwyClosedNotams.flatMap((n: any) => rwyClosedToEvents(n))]);
                setFetchingNotams(false);
            });
    }, []);

    useEffect(() => {
        setTitle('Opening Hours ENHD');
    }, []);

    const getEventColor = (type: string) => {
        switch (type) {
            case 'Open':
                return theme.palette.primary.main;
            case 'Closed':
                return theme.palette.error.main;
            case 'Rwy Closed':
                return theme.palette.warning.main;
            default:
                return theme.palette.info.main;
        }
    };

    const eventStyleGetter = (event: any, _start: any, _end: any, isSelected: any) => {
        return {
            style: {
                opacity: isSelected ? 1 : 0.9,
                borderColor: getEventColor(event.type),
                backgroundColor: getEventColor(event.type)
            }
        };
    };

    return (
        <Container maxWidth={false}>
            <Typography variant="h3" component="h1" gutterBottom sx={{ color: theme.palette.text.primary }}>
                <LockClockIcon fontSize="large" /> Opening Hours
            </Typography>
            <Typography variant="body1" paddingBottom={theme.spacing(2)} sx={{ color: theme.palette.text.primary }}>
                The opening hours displayed are fetched from active and upcoming NOTAMs for ENHD and ENSO. Times displayed are in local time. Use this at your
                own risk, and verify with official NOTAMs before flying.
            </Typography>
            {fetchingNotams && (
                <Paper sx={{ padding: theme.spacing(2), marginBottom: theme.spacing(-1) }}>
                    <CircularProgress size={64} />
                </Paper>
            )}
            {!fetchingNotams && (
                <Paper sx={{ padding: theme.spacing(2), marginBottom: theme.spacing(-1) }}>
                    {/* @ts-ignore */}
                    <BigCalendar
                        // @ts-ignore
                        events={events}
                        // @ts-ignore
                        backgroundEvents={backgroundEvents}
                        localizer={localizer}
                        startAccessor="start"
                        endAccessor="end"
                        allDayAccessor="allDay"
                        style={{ height: (height ?? 0) - 335 }}
                        showAllEvents
                        dayLayoutAlgorithm="no-overlap"
                        min={minDate}
                        max={maxDate}
                        eventPropGetter={eventStyleGetter}
                    />
                </Paper>
            )}
        </Container>
    );
};

export default OpeningHours;
