import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { default as React, useContext } from 'react';
import packageJson from '../../package.json';
import { ColorModeContext } from './Layout';

const Footer = (): React.ReactElement => {
    const theme = useTheme();
    const colorMode = useContext(ColorModeContext);

    return (
        <footer
            style={{
                flexShrink: 0,
                padding: theme.spacing(2, 4),
                backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[800]
            }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="subtitle2" align="left" color="textSecondary" component="p">
                        <IconButton onClick={() => colorMode.toggleColorMode()}>
                            {theme.palette.mode === 'dark' && <DarkModeIcon />}
                            {theme.palette.mode === 'light' && <LightModeIcon />}
                        </IconButton>
                        TAF/METAR from met.no, NOTAM from FAA
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle2" align="right" color="textSecondary" component="p">
                        Makani version {packageJson.version}{' '}
                    </Typography>
                </Grid>
            </Grid>
        </footer>
    );
};

export default Footer;
