import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Snackbar, Tab, Tabs } from '@mui/material';
import { useTheme } from '@mui/system';
import React, { useState } from 'react';
import Templates from '../../data/templates.json';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function TabPanel({ children, value, index, ...other }: { children?: React.ReactNode; index: number; value: number }) {
    const theme = useTheme();

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Paper sx={{ padding: theme.spacing(2), borderTopLeftRadius: 0, borderTopRightRadius: 0 }} elevation={3}>
                    <pre style={{ maxWidth: '100%', wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>{children}</pre>
                </Paper>
            )}
        </div>
    );
}

const TemplateDialog = ({ open, onClose }: { open: boolean; onClose: () => void }): React.ReactElement => {
    const theme = useTheme();

    const [value, setValue] = useState(0);
    const [templateCopied, setTemplateCopied] = useState(false);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const copy = () => {
        const activeTemplate = Templates.at(value);
        if (activeTemplate) {
            navigator.clipboard.writeText(activeTemplate?.template);
            setTemplateCopied(true);
        }
    };

    return (
        <Dialog open={open} fullWidth maxWidth="xl" onClose={onClose}>
            <DialogTitle>
                Templates
                <IconButton style={{ float: 'right' }} onClick={() => onClose()} size="small">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box>
                    <Tabs value={value} onChange={handleChange}>
                        {Templates.map((template) => (
                            <Tab key={template.name} label={template.name} />
                        ))}
                    </Tabs>
                </Box>
                {Templates.map((template, index) => (
                    <TabPanel key={template.name} value={value} index={index}>
                        {template.template}
                    </TabPanel>
                ))}
            </DialogContent>
            <DialogActions sx={{ paddingRight: theme.spacing(3), paddingBottom: theme.spacing(2) }}>
                <Button variant="outlined" size="large" startIcon={<ContentCopyIcon />} onClick={copy}>
                    Copy
                </Button>
            </DialogActions>
            <Snackbar
                open={templateCopied}
                autoHideDuration={3000}
                onClose={() => setTemplateCopied(false)}
                message="Template copied to clipboard"
                color="info"
            />
        </Dialog>
    );
};

export default TemplateDialog;
