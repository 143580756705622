import { Identifier, IdentifierType } from '../models/Identifiers';
import { iocPatterns as identifierPatterns } from './IdentifierPatterns';

export const parseIdentifiers = (identifierList: string): Identifier[] => {
    const list = identifierList.split('\n').map((ioc) => ioc.trim());
    const parsedIdentifiers: Identifier[] = [];
    list.map((identifier) => {
        const result = identifierPatterns.filter((p) => p.pattern.test(identifier));
        if (result && result.length > 0) {
            if (parsedIdentifiers.filter((i) => i.content === identifier).length === 0) {
                parsedIdentifiers.push({ type: result[0].type, content: identifier });
            }
        }
    });

    return parsedIdentifiers;
};

export const getIdentifierTypeName = (type?: IdentifierType) => {
    switch (type) {
        case IdentifierType.AircraftRegistration:
            return 'Aircraft Registration';
        case IdentifierType.AirportCode:
            return 'Airport ICAO Code';
        default:
            return 'Unknown';
    }
};
