import { Chip, FormControl, InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IdentifierType } from '../../models/Identifiers';
import { getIdentifierTypeName, parseIdentifiers } from '../../tools/ParseIdentifiers';
import ActionsAircraft from './ActionsAircraft';
import ActionsAirport from './ActionsAirport';
import AircraftCountrySearch from './AircraftCountrySearch';
import AirportNameSearch from './AirportNameSearch';

const OmniBox = (): React.ReactElement => {
    const [search, setSearch] = useState('');
    const [type, setType] = useState<IdentifierType | undefined>();
    const [typeName, setTypeName] = useState<string | undefined>();

    useEffect(() => {
        const ioc = parseIdentifiers(search).at(0);
        setType(ioc?.type);
        setTypeName(ioc ? getIdentifierTypeName(ioc.type) : undefined);
    }, [search]);

    return (
        <>
            <FormControl fullWidth>
                <TextField
                    label="Input aircraft registration, ICAO airport code, etc."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{typeName && <Chip label={typeName} />}</InputAdornment>
                    }}
                    autoComplete="off"
                />
            </FormControl>
            {type === IdentifierType.AirportCode && <AirportNameSearch search={search} />}
            {type === IdentifierType.AircraftRegistration && <AircraftCountrySearch search={search} />}
            {type === IdentifierType.AircraftRegistration && <ActionsAircraft search={search} />}
            {type === IdentifierType.AirportCode && <ActionsAirport search={search} />}
        </>
    );
};

export default OmniBox;
