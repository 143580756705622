import { Business, PersonOutline, Summarize, School, Air, Map, RequestPage, EventNote, LibraryBooks, Groups } from '@mui/icons-material';

export const launcherItems = [
    {
        category: 'Personlig',
        title: 'Min Side - Luftfartstilsynet',
        link: 'https://luftfartstilsynet.no/minside/',
        icon: Business,
        description: ''
    },
    {
        category: 'Personlig',
        title: 'Min idrett',
        link: 'https://minidrett.nif.no/',
        icon: PersonOutline,
        description: ''
    },
    {
        category: 'NLF',
        title: 'OBSREG',
        link: 'https://app.nlf.no/home',
        icon: Summarize,
        description: ''
    },
    {
        category: 'NLF',
        title: 'TMS',
        link: 'https://tms.nlf.no/min-side',
        icon: School,
        description: ''
    },
    {
        category: 'WX-symboler og modeller',
        title: 'WX Symbols',
        link: 'http://weather.rap.ucar.edu/info/wxSymbols_anno1.pdf',
        icon: Air,
        description: ''
    },
    {
        category: 'WX-symboler og modeller',
        title: 'Station model',
        link: 'https://en.wikipedia.org/wiki/Station_model',
        icon: Air,
        description: ''
    },
    {
        category: 'WX-symboler og modeller',
        title: 'Plotted WX Symbols',
        link: 'https://www.wpc.ncep.noaa.gov/dailywxmap/plottedwx.html',
        icon: Air,
        description: ''
    },
    {
        category: 'Verktøy og ressurser',
        title: 'Avinor IPPC',
        link: 'https://www.ippc.no/ippc/index.jsp',
        icon: Map,
        description: ''
    },
    {
        category: 'Verktøy og ressurser',
        title: 'MyPPR',
        link: 'https://www.myppr.no/',
        icon: RequestPage,
        description: ''
    },
    {
        category: 'Verktøy og ressurser',
        title: 'myWebLog',
        link: 'https://www.myweblog.se/',
        icon: EventNote,
        description: ''
    },
    {
        category: 'Verktøy og ressurser',
        title: 'AIP Norway',
        link: 'https://avinor.no/en/ais/aipnorway/',
        icon: LibraryBooks,
        description: ''
    },
    {
        category: 'Tilsyn og myndigheter',
        title: 'Norsk Luftsportstilsyn',
        link: 'https://www.luftsportstilsynet.no/',
        icon: Business,
        description: 'Tilsyn for ballong og seil'
    },
    {
        category: 'Tilsyn og myndigheter',
        title: 'Luftfartstilsynet',
        link: 'https://luftfartstilsynet.no/',
        icon: Business,
        description: ''
    },
    {
        category: 'Flyklubber',
        title: 'Haugaland Flyklubb',
        link: 'https://www.haugalandflyklubb.no/',
        icon: Groups,
        description: ''
    },
    {
        category: 'Flyklubber',
        title: 'Haugaland Seilflyklubb',
        link: 'https://www.hsfk.no/',
        icon: Groups,
        description: ''
    },
    {
        category: 'Flyklubber',
        title: 'Sola Flyklubb',
        link: 'https://www.solaflyklubb.no/',
        icon: Groups,
        description: ''
    },
    {
        category: 'Flyklubber',
        title: 'Stord Flyklubb',
        link: 'https://stordflyklubb.no/',
        icon: Groups,
        description: ''
    },
    {
        category: 'Flyklubber',
        title: 'Valdres Flyklubb',
        link: 'http://www.valdresflyklubb.nlf.no/',
        icon: Groups,
        description: ''
    }];