import Grid from '@mui/material/Grid';
import React, { useEffect, useMemo, useState } from 'react';
import Airport from './Airport';

const Airports = (): React.ReactElement => {
    const [rawMetars, setRawMetars] = useState<string[]>([]);
    const [rawTafs, setRawTafs] = useState<string[]>([]);
    const [notams, setNotams] = useState<any>({});

    const [fetchingMetars, setFetchingMetars] = useState(true);
    const [fetchingTafs, setFetchingTafs] = useState(true);
    const [fetchingNotams, setFetchingNotams] = useState(true);

    useEffect(() => {
        fetch('https://makani.limanovember.aero/api/met/metar')
            .then((result) => result.text())
            .then((result) => {
                setRawMetars(result.split('\n'));
                setFetchingMetars(false);
            });

        fetch('https://makani.limanovember.aero/api/met/taf')
            .then((result) => result.text())
            .then((result) => {
                setRawTafs(result.split('\n'));
                setFetchingTafs(false);
            });

        fetch('https://makani.limanovember.aero/api/faa/notams')
            .then((result) => result.json())
            .then((result) => {
                setNotams(result);
                setFetchingNotams(false);
            });
    }, []);

    const enhdMetars = useMemo(() => rawMetars.filter((m: string) => m.startsWith('ENHD')), [rawMetars]);
    const ensoMetars = useMemo(() => rawMetars.filter((m: string) => m.startsWith('ENSO')), [rawMetars]);
    const enzvMetars = useMemo(() => rawMetars.filter((m: string) => m.startsWith('ENZV')), [rawMetars]);

    const enhdTafs = useMemo(() => rawTafs.filter((t: string) => t.startsWith('ENHD')), [rawTafs]);
    const ensoTafs = useMemo(() => rawTafs.filter((t: string) => t.startsWith('ENSO')), [rawTafs]);
    const enzvTafs = useMemo(() => rawTafs.filter((t: string) => t.startsWith('ENZV')), [rawTafs]);

    const enhdNotams = useMemo(() => notams?.notamList?.filter((n: any) => n.facilityDesignator === 'ENHD'), [notams]);
    const ensoNotams = useMemo(() => notams?.notamList?.filter((n: any) => n.facilityDesignator === 'ENSO'), [notams]);
    const enzvNotams = useMemo(() => notams?.notamList?.filter((n: any) => n.facilityDesignator === 'ENZV'), [notams]);

    return (
        <Grid container>
            <Airport
                icao="ENHD"
                metars={enhdMetars}
                tafs={enhdTafs}
                notams={enhdNotams}
                fetchingMetars={fetchingMetars}
                fetchingTafs={fetchingTafs}
                fetchingNotams={fetchingNotams}
                yr="https://www.yr.no/nb/v%C3%A6rvarsel/daglig-tabell/2-6296740/Norge/Rogaland/Karm%C3%B8y/Haugesund%20lufthavn,%20Karm%C3%B8y"
                yrRadar="https://www.yr.no/nb/kart/radar/2-6296740/Norge/Rogaland/Karm%C3%B8y/Haugesund%20lufthavn,%20Karm%C3%B8y"
                yrDetails="https://www.yr.no/nb/detaljer/tabell/2-6296740/Norge/Rogaland/Karm%C3%B8y/Haugesund%20lufthavn,%20Karm%C3%B8y"
                windy="https://www.windy.com/ENHD?59.386,5.062,11"
                windyStation="https://www.windy.com/station/ENHD?59.386,5.062,11"
                runways={[13, 31]}
                coordinates={{ latitude: 59.342881, longitude: 5.213238 }}
            />
            <Airport
                icao="ENSO"
                metars={ensoMetars}
                tafs={ensoTafs}
                notams={ensoNotams}
                fetchingMetars={fetchingMetars}
                fetchingTafs={fetchingTafs}
                fetchingNotams={fetchingNotams}
                yr="https://www.yr.no/nb/v%C3%A6rvarsel/daglig-tabell/1-2270713/Norge/Vestland/Stord/Stord%20lufthamn,%20S%C3%B8rstokken"
                yrRadar="https://www.yr.no/nb/kart/radar/1-2270713/Norge/Vestland/Stord/Stord%20lufthamn,%20S%C3%B8rstokken"
                yrDetails="https://www.yr.no/nb/detaljer/tabell/1-2270713/Norge/Vestland/Stord/Stord%20lufthamn,%20S%C3%B8rstokken"
                windy="https://www.windy.com/ENSO?59.795,5.352,11"
                windyStation="https://www.windy.com/station/ENSO?59.795,5.352,11"
                runways={[14, 32]}
                coordinates={{ latitude: 59.792203, longitude: 5.34056 }}
            />
            <Airport
                icao="ENZV"
                metars={enzvMetars}
                tafs={enzvTafs}
                notams={enzvNotams}
                fetchingMetars={fetchingMetars}
                fetchingTafs={fetchingTafs}
                fetchingNotams={fetchingNotams}
                yr="https://www.yr.no/nb/v%C3%A6rvarsel/daglig-tabell/1-15399/Norge/Rogaland/Sola/Stavanger%20lufthavn,%20Sola"
                yrRadar="https://www.yr.no/nb/kart/radar/1-15399/Norge/Rogaland/Sola/Stavanger%20lufthavn,%20Sola"
                yrDetails="https://www.yr.no/nb/detaljer/tabell/1-15399/Norge/Rogaland/Sola/Stavanger%20lufthavn,%20Sola"
                windy="https://www.windy.com/ENZV?58.868,5.806,11"
                windyStation="https://www.windy.com/station/ENZV?58.868,5.806,11"
                runways={[10, 28, 18, 36]}
                coordinates={{ latitude: 58.87683, longitude: 5.637752 }}
            />
        </Grid>
    );
};

export default Airports;
