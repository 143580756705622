import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LaunchIcon from '@mui/icons-material/Launch';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Button, Card, CardContent, CardHeader, Snackbar, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import gpxparser from 'gpxparser';
import React, { useEffect, useState } from 'react';
import { calculateDistance, convertMeters, convertMsToKts } from '../../tools/Conversions';
import { setTitle } from '../../tools/SetTitle';
import { parseTcx } from '../../tools/TcxParser';
import { uniqueValues } from '../../tools/UniqueValues';
import Number from '../common/Number';
import MetarFetch from './MetarFetch';
import TemplateDialog from './TemplateDialog';
import TrackCleaner from './TrackCleaner';

const Debrief = (): React.ReactElement => {
    const theme = useTheme();

    const [selectedFile, setSelectedFile] = React.useState<any>(null);
    const [filename, setFilename] = useState('');

    const [trackCreator, setTrackCreator] = useState('');
    const [track, setTrack] = useState<any>();
    const [trackTitle, setTrackTitle] = useState('');
    const [maxAltitude, setMaxAltitude] = useState(0);
    const [averageAltitude, setAverageAltitude] = useState(0);
    const [maxSpeed, setMaxSpeed] = useState(0);
    const [averageSpeed, setAverageSpeed] = useState(0);
    const [totalDistance, setTotalDistance] = useState(0);
    const [predictedUrl, setPredictedUrl] = useState('');

    const [parsedDate, setParsedDate] = useState<string | null>(null);
    const [parsedDeparture, setParsedDeparture] = useState<string | null>(null);
    const [parsedArrival, setParsedArrival] = useState<string | null>(null);
    const [parsedAirports, setParsedAirports] = useState<string | null>(null);

    const [urlCopied, setUrlCopied] = useState(false);

    const [templateDialogOpen, setTemplateDialogOpen] = useState(false);
    const [trackCleanerDialogOpen, setTrackCleanerDialogOpen] = useState(false);

    useEffect(() => {
        setTitle('Debriefing');
    }, []);

    useEffect(() => {
        if (!track) {
            return;
        }

        const distance = track.points.reduce((prev: any, point: any, index: number, points: any[]) => {
            if (index === 0) {
                return prev;
            }

            return (
                prev + calculateDistance({ Latitude: points[index - 1].lat, Longitude: points[index - 1].lon }, { Latitude: point.lat, Longitude: point.lon })
            );
        }, 0);

        const elevations = track.points.map((t: any) => convertMeters(t.ele, 'ft')); // from meters to feet

        setTotalDistance(distance); // from meters to nautical miles
        setMaxAltitude(Math.max(...elevations));
        setAverageAltitude(Math.round(elevations.reduce((p: any, c: any) => p + (!isNaN(c) ? c : 0), 0) / elevations.length));

        const speeds = track.points.map((t: any) => convertMsToKts(t.speed)); // from m/s to kts

        setMaxSpeed(Math.round(Math.max(...speeds)));
        setAverageSpeed(Math.round(speeds.reduce((p: any, c: any) => p + (!isNaN(c) ? c : 0), 0) / speeds.length));
    }, [track]);

    const handleCapture = async ({ target }: { target: any }) => {
        setSelectedFile(target.files[0]);

        const fileReader = new FileReader();
        fileReader.onload = async (e) => {
            const encoder = new TextDecoder('utf-8');

            if (!e.target?.result || e.target.result === undefined) {
                return;
            }

            const decodedXml = e?.target?.result instanceof ArrayBuffer ? encoder.decode(e?.target?.result) : e?.target?.result;

            if (target.files[0].name.toLowerCase().endsWith('.tcx')) {
                console.log('File is TCX');
                const parsedTcx = parseTcx(decodedXml);

                if (parsedTcx === undefined || parsedTcx.points.length === 0) {
                    setTrack(undefined);
                    return;
                }

                setTrack({ name: parsedTcx.name, points: parsedTcx.points });
                setTrackCreator(/creator="([\w .]+)"/gm.exec(decodedXml)?.at(1) ?? 'Makani');

                setTrackTitle(parsedTcx.name);

                const start = new Date(parsedTcx.points.at(0)?.time);
                const end = new Date(parsedTcx.points.at(-1)?.time);

                setParsedDate(start?.toUTCString() ?? '');
                setParsedDeparture(start?.toUTCString() ?? '');
                setParsedArrival(end?.toUTCString() ?? '');
            } else if (target.files[0].name.toLowerCase().endsWith('.gpx')) {
                console.log('File is GPX');
                const gpx = new gpxparser();
                gpx.parse(decodedXml);

                if (gpx.tracks.length === 0) {
                    setTrack(undefined);
                    return;
                }

                setTrack(gpx.tracks[0]);
                setTrackCreator(/creator="([\w .]+)"/gm.exec(decodedXml)?.at(1) ?? 'Makani');

                setTrackTitle(gpx.tracks[0].name);

                const start = gpx.tracks[0].points.at(0)?.time;
                const end = gpx.tracks[0].points.at(-1)?.time;

                const airports = [...gpx.tracks[0].name.matchAll(/EN[A-Z][A-Z]/gim)]
                    .map((x) => x[0])
                    .filter(uniqueValues)
                    .join(' ');

                setParsedAirports(airports);

                setParsedDate(start?.toUTCString() ?? '');
                setParsedDeparture(start?.toUTCString() ?? '');
                setParsedArrival(end?.toUTCString() ?? '');
            } else {
                return;
            }

            setFilename(target.files[0].name);
            let fileName = target.files[0].name.replace('.gpx', '').replace('.tcx', '');
            const fileNameParts = fileName.split('-');

            if (fileNameParts.length > 3) {
                const number = parseInt(fileNameParts[3], 10);
                fileName = `${fileNameParts[0]}-${fileNameParts[1]}-${fileNameParts[2]}${number > 1 ? `-${number}` : ''}`;
            }

            setPredictedUrl(`https://flightbook.limanovember.aero/flight/${fileName}`);
        };

        fileReader.readAsArrayBuffer(target.files[0]);
    };

    const copyPredictedUrl = () => {
        navigator.clipboard.writeText(predictedUrl);
        setUrlCopied(true);
    };

    return (
        <Container maxWidth="xl">
            <Typography variant="h3" component="h1" gutterBottom sx={{ color: theme.palette.text.primary }}>
                Debriefing tool
            </Typography>
            <Paper sx={{ padding: 2 }}>
                <input accept="application/gpx+xml" id="gpxFile" type="file" onChange={handleCapture} style={{ display: 'none' }} />
                <Tooltip title="Select GPX">
                    <label htmlFor="gpxFile" style={{ cursor: 'pointer' }}>
                        <Button color="primary" aria-label="upload GPX" component="span" startIcon={<UploadFileIcon />} size="large" variant="outlined">
                            {selectedFile ? selectedFile.name : 'Select GPX...'}
                        </Button>
                    </label>
                </Tooltip>
                <Button
                    variant="outlined"
                    size="large"
                    endIcon={<LaunchIcon />}
                    component="a"
                    href="https://www.safelogweb.com/logbook/SingleFlight.aspx"
                    sx={{ float: 'right' }}>
                    Create log entry
                </Button>
                <Button
                    disabled={!track}
                    variant="outlined"
                    size="large"
                    color="primary"
                    onClick={() => setTrackCleanerDialogOpen(true)}
                    startIcon={<CleaningServicesIcon />}
                    sx={{ float: 'right', marginRight: theme.spacing(2) }}>
                    Track Cleaner
                </Button>
                <Button
                    variant="outlined"
                    size="large"
                    color="info"
                    onClick={() => setTemplateDialogOpen(true)}
                    startIcon={<TextSnippetIcon />}
                    sx={{ float: 'right', marginRight: theme.spacing(2) }}>
                    Templates
                </Button>
            </Paper>
            {selectedFile && (
                <Card sx={{ marginTop: theme.spacing(3) }}>
                    <CardHeader title={trackTitle} />
                    <CardContent>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th">Total distance</TableCell>
                                    <TableCell>
                                        <Number value={totalDistance} numberOfDecimals={1} unit="nm" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th">Max altitude</TableCell>
                                    <TableCell>
                                        <Number value={maxAltitude} numberOfDecimals={0} unit="ft" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th">Average altitude</TableCell>
                                    <TableCell>
                                        <Number value={averageAltitude} numberOfDecimals={0} unit="ft" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th">Max speed</TableCell>
                                    <TableCell>
                                        <Number value={maxSpeed} numberOfDecimals={0} unit="kt" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th">Average speed</TableCell>
                                    <TableCell>
                                        <Number value={averageSpeed} numberOfDecimals={0} unit="kt" />
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0, paddingBottom: 0, marginBottom: 0 } }}>
                                    <TableCell component="th">Predicted Flightbook URL</TableCell>
                                    <TableCell>
                                        {predictedUrl}{' '}
                                        <IconButton size="small" title="Copy to clipboard" onClick={copyPredictedUrl}>
                                            <ContentCopyIcon fontSize="inherit" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            )}
            <Snackbar open={urlCopied} autoHideDuration={3000} onClose={() => setUrlCopied(false)} message="URL copied to clipboard" color="info" />
            <MetarFetch parsedDate={parsedDate} parsedDeparture={parsedDeparture} parsedArrival={parsedArrival} parsedAirports={parsedAirports} />
            <TemplateDialog open={templateDialogOpen} onClose={() => setTemplateDialogOpen(false)} />
            <TrackCleaner
                open={trackCleanerDialogOpen}
                onClose={() => setTrackCleanerDialogOpen(false)}
                track={track}
                setTrack={setTrack}
                trackCreator={trackCreator}
                filename={filename}
            />
        </Container>
    );
};

export default Debrief;
