import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/nb';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import minMax from 'dayjs/plugin/minMax';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import Layout from './components/Layout';

if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') {
    console.log = function () {};
}

const configureDayJs = (): void => {
    dayjs.extend(relativeTime);
    dayjs.extend(customParseFormat);
    dayjs.extend(advancedFormat);
    dayjs.extend(isBetween);
    dayjs.extend(isSameOrBefore);
    dayjs.extend(isSameOrAfter);
    dayjs.extend(localeData);
    dayjs.extend(updateLocale);
    dayjs.extend(localizedFormat);
    dayjs.extend(duration);
    dayjs.extend(timezone);
    dayjs.extend(weekday);
    dayjs.extend(minMax);
    dayjs.extend(isoWeek);
    dayjs.extend(utc);

    dayjs.updateLocale('en', {
        weekStart: 1
    });
};

const App = (): React.ReactElement => {
    configureDayJs();

    return (
        <Router>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nb">
                <Layout />
            </LocalizationProvider>
        </Router>
    );
};

export default App;
